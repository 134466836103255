/* eslint-disable no-return-assign */

import ProductLabel from 'app/design/frontend/Advox/pwa/src/component/ProductLabel';
import { withRouter } from 'react-router';
import SlickSlider from 'react-slick';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import Icons from 'Component/Icons';
import Image from 'Component/Image';
import ProductCompareButton from 'Component/ProductCompareButton';
import ProductGalleryBaseImage from 'Component/ProductGalleryBaseImage';
import { ARRANGEMENT_PRODUCT_TYPE_ID } from 'Component/ProductHeader/ProductHeader.config';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import VideoPopup from 'Component/VideoPopup';
import { ProductGallery as SourceProductGallery } from 'SourceComponent/ProductGallery/ProductGallery.component';

import './ProductGallery.override.style';

/** @namespace Pwa/Component/ProductGallery/Component/ProductGallery */
export class ProductGallery extends SourceProductGallery {
    renderWishlistButton() {
        const { productData } = this.props;

        if (productData.length === 0) {
            return null;
        }

        return (
            <ProductWishlistButton
                magentoProduct={productData}
                mix={{
                    block: this.className,
                    elem: 'WishListButton',
                }}
            />
        );
    }

    renderLabel() {
        const { labels } = this.props;

        return <ProductLabel labels={labels} />;
    }

    renderImage(mediaData, index) {
        const { isZoomEnabled, handleZoomChange, disableZoom, isMobile, isImageZoomPopupActive, showLoader } =
            this.props;
        const { scrollEnabled } = this.state;

        if (!isMobile) {
            const { base: { url: baseSrc } = {}, large: { url: largeSrc } = {} } = mediaData;

            const style = isImageZoomPopupActive ? { height: 'auto' } : {};
            const src = isImageZoomPopupActive ? largeSrc : baseSrc;

            return (
                <div block="ProductGallery" elem="ImageWrapper" onClick={this.handleSliderClick.bind(this)}>
                    <Image
                        key={index}
                        src={src}
                        ratio="custom"
                        mix={{
                            block: 'ProductGallery',
                            elem: 'SliderImage',
                            mods: { isPlaceholder: !src },
                        }}
                        isPlaceholder={!src}
                        style={style}
                        showIsLoading={showLoader}
                        magnification={{ enabled: isImageZoomPopupActive }}
                        loadingForCrawlers={index === 0 ? 'eager' : null}
                    />
                </div>
            );
        }

        return (
            <TransformWrapper
                key={index}
                onZoomChange={handleZoomChange}
                onWheelStart={this.onWheelStart}
                onWheel={this.onWheel}
                wheel={{ limitsOnWheel: true, disabled: !scrollEnabled }}
                //   doubleClick={ { mode: 'reset' } }
                pan={{
                    disabled: !isZoomEnabled,
                    limitToWrapperBounds: true,
                    velocity: false,
                }}
                options={{
                    limitToBounds: true,
                    minScale: 1,
                }}
            >
                {({ scale, previousScale, resetTransform, setTransform }) => {
                    if (scale === 1 && previousScale !== 1) {
                        resetTransform();
                    }

                    return (
                        <ProductGalleryBaseImage
                            setTransform={setTransform}
                            index={index}
                            mediaData={mediaData}
                            scale={scale}
                            previousScale={previousScale}
                            disableZoom={disableZoom}
                            isZoomEnabled={isZoomEnabled}
                        />
                    );
                }}
            </TransformWrapper>
        );
    }

    renderCompareButton() {
        const {
            productId,
            product: { type_id },
        } = this.props;

        const isArrangement = type_id === ARRANGEMENT_PRODUCT_TYPE_ID;

        if (!productId || isArrangement) {
            return null;
        }

        return (
            <ProductCompareButton
                productId={productId}
                mix={{
                    block: this.className,
                    elem: 'ProductCompareButton',
                    mods: { isGrey: true },
                }}
            />
        );
    }

    renderActionButtons() {
        const {
            device: { isMobile },
        } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <div block="ProductGallery" elem="ActionButtons">
                {this.renderWishlistButton()}
                {this.renderCompareButton()}
            </div>
        );
    }

    __construct(props) {
        super.__construct(props);
        this.state = {
            mainSlider: null,
            navSlider: null,
        };
    }

    componentDidMount() {
        this.setState({
            mainSlider: this.mainSlider,
            navSlider: this.navSlider,
        });
    }

    renderSlider() {
        const { gallery, isMobile, isImageZoomPopupActive } = this.props;

        const isMoreThanOnePhoto = gallery.length > 1;

        return (
            <div ref={this.imageRef} block="ProductGallery" elem="SliderWrapper">
                <meta itemProp="image" content={this.getImageUrl()} />

                <div block="ProductGallery" elem="MainSlider">
                    <SlickSlider
                        asNavFor={this.state.navSlider}
                        prevArrow={<Icons name="arrow_left" fill="#10069F" />}
                        nextArrow={<Icons name="arrow_right" fill="#10069F" />}
                        ref={(slider) => (this.mainSlider = slider)}
                    >
                        {gallery.map((media, key) => this.renderSlide({ ...media, isMain: true }, key))}
                    </SlickSlider>
                </div>
                {isMoreThanOnePhoto && !isMobile && (
                    <div block="ProductGallery" elem="NavSlider">
                        <SlickSlider
                            block="ProductGallery"
                            elem="NavSlider"
                            asNavFor={this.state.mainSlider}
                            ref={(slider) => (this.navSlider = slider)}
                            slidesToShow={isImageZoomPopupActive ? 8 : 4}
                            infinite={false}
                            swipeToSlide
                            focusOnSelect
                            prevArrow={<Icons name="arrow_left" fill="#10069F" />}
                            nextArrow={<Icons name="arrow_right" fill="#10069F" />}
                        >
                            {gallery.map(this.renderAdditionalPicture)}
                        </SlickSlider>
                    </div>
                )}
            </div>
        );
    }

    renderFloatingArrangementButton() {
        const { arrangementPhotoSrc, isFloatingArrangementButton, productType, history, isMobile } = this.props;

        if (productType === 'built' || !isFloatingArrangementButton) {
            return null;
        }

        return (
            <button block="ProductGallery" elem="FloatingButton" onClick={history.goBack}>
                {!isMobile && <img src={arrangementPhotoSrc} alt={__('Back to set')} />}
                <span block="ProductGallery" elem="FloatingText">
                    {__('Back to set')}
                </span>
            </button>
        );
    }

    render() {
        return (
            <div block="ProductGallery" ref={this.galleryRef}>
                {this.renderFloatingArrangementButton()}
                {this.renderLabel()}
                {this.renderActionButtons()}
                {this.renderSlider()}
                <VideoPopup />
            </div>
        );
    }
}

export default withRouter(ProductGallery);
